<template>
  <div>     
    <template v-if="user.canal == 3">
      <propio v-if="!isMobile"></propio>
      <propioMovil v-else></propioMovil>
    </template>
   <template v-if="user.canal == 1">
      <retail v-if="!isMobile"></retail> 
      <retailMovil v-else></retailMovil>
    </template>
    <template v-if="user.canal == 4">
      <especialista v-if="!isMobile"></especialista> 
      <espMovil v-else></espMovil> 
    </template>
  </div>
</template>

<script>
import propio from './Propio.vue'; 
import retail from './Retail.vue';
import especialista from './Especialista.vue';
import propioMovil from './PropioMovil.vue'; 
import espMovil from './espMovil.vue';
import retailMovil from './RetailMovil.vue';
export default {
  name: 'Ranking',
  components:{ 
    propio,
    retail,
    especialista,
    propioMovil,
    espMovil,
    retailMovil
  },
  data(){
    return{
      isMobile: this.$mobile(),
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  }, 
}
</script>